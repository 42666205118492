html,
body {
    height: 100%;
    min-height: 100%;
    color: #111;
    font-size: 10pt;
    font-family: 'Ubuntu', sans-serif;
    background-image: -webkit-linear-gradient(270deg, #f5f5f5, #fff);
    background-image: linear-gradient(180deg, #f5f5f5, #fff);
    background-repeat: no-repeat;
    background-size: 100% 200px;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: lighter;
}

.navbar-inverse.navbar {
    background-color: #449d44;
}

.about {
    margin-top: 15px;
    margin-bottom: 15px;
}

.green .navbar-right {
    margin-right: 0;
}

.btn-primary:not(.btn-link):not(.btn-flat) {
    background-color: #208843;
    border-color: #398439;
}

.btn-primary:hover:not(.btn-link):not(.btn-flat) {
    background-color: #449d44;
    border-color: #398439;
}

a, .link {
    color: #208843;
    cursor: pointer;
}

a:hover, .link:hover {
    color: #449d44;
    cursor: pointer;
    text-decoration: underline;
}

.btn-success {
    background-color: #208843;
}

.btn-success:hover {
    background-color: #449d44;
}

#campus-session-id {
    min-width: 270px;
}

#progress {
    display: none;
}

#message-box {
    padding: 10px;
}

.clear {
    clear: both;
}

ul {
    list-style: square;
}

a, .link {
    cursor: pointer;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

.row:after {
    display: block;
    clear: both;
    content: "";
}

.information-link {
    display: block;
    margin-bottom: 10px;
    text-align: center;
    font-size: 11pt;
    cursor: pointer;
}

.white {
    background-color: #FFF;
}

.green {
    background-color: #208843;
}

.green .navbar-right {
    margin-right: 0;
}

#support-modal {
    color: #000000;
}

#support-modal a,
.nav-tabs > li > a {
    color: #208843;
}

#moderator,
#new_message {
    margin-bottom: 10px;
}

a,
.btn,
textarea,
.input-group-addon:first-child,
input {
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
}

a,
.btn {
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

a:hover,
.btn:hover {
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.btn:focus,
.btn:active:focus {
    outline: none !important;
}

.dummy {
    padding-top: 30px;
    color: #fff;
    text-align: center;
}

.dummy .btn-default {
    background-color: #00a651;
    color: #FFF;
    border-color: #19b464;
}

.dummy .btn-default:hover {
    background-color: #00aa44;
    color: #FFF;
}

.dummy a{
    color: #ecc103;
}

.dummy a:hover {
    color: #fff;
}

.public-wrap {
    background-color: #208843;
    margin-bottom: 0 !important;
    padding-bottom: 50px;
}

.remeber-me input[type="checkbox"]:checked + .checkbox {
    background-position: 0;
}

header {
    color: #208944;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEE;
    letter-spacing: 1px;
    font-size: 10pt;
    text-transform: uppercase;
    vertical-align: middle;
}

.header-logo {
    margin: 0 0 0 50px;
    height: 60px;
}

.header-logo img {
    position: relative;
    margin: auto;
    height: 100%;
}

.header-nav .navbar {
    display: block;
    margin: 0 18px 0 0;
    border: none;
    color: #3c9;
}

.top-header {
    height: 60px;
}

.top-header:after {
    display: block;
    clear: both;
    content: "";
}

.menu-toggle {
    display: none;
    margin: 10px 0 0 10px;
    width: 40px;
    height: 40px;
    border: 1px solid #208843;
    border-radius: 4px;
    color: #208843;
    text-align: center;
    font-size: 1.5em;
    cursor: pointer;
}

.menu-toggle:hover {
    background: #208843;
    color: #fff;
}

.menu-toggle i {
    font-size: 1.1em;
    line-height: 40px;
}

.navbar ul {
    margin: 0;
    text-align: right;
}

.navbar ul > li {
    display: inline-block;
    vertical-align: middle;
}

.navbar-brand{
    font-size: 9pt;
}

.carousel {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
    max-height: 213px;
    height: 193px;
}

.content {
    /*overflow: hidden;*/
    margin-bottom: 25px;
    background-color: #FFF;
}

.public-wrap .content {
    background-color: #208944;
}

.content .left-col {
    display: block;
    float: left;
    width: 218px;
}

.content .md-col {
    overflow: hidden;
    margin: 0 0 0 218px;
    margin-bottom: 125px;
    padding: 0;
    padding-left: 10px;
    min-height: 35px;
}

.content .right-col {
    position: absolute;
    top: 210px;
    right: 0;
    z-index: 999;
    width: 0;
    background: #208843;
    color: #fff;
}

.right-col.show {
    right: 0 !important;
    width: 220px;
}

.left-nav {
    margin: 0;
    padding: 0;
}

.left-nav a {
    position: relative;
    display: block;
    padding: 8px 18px 8px 12px;
    color: #9a6505;
    text-decoration: none;
}

.left-nav a:hover {
    color: #694709;
    text-decoration: none;
}

.wrap {
    margin-top: 30px;
    margin-bottom: 30px;
}

footer {
    border-top: 2px solid #208944;
    padding-top: 15px;
    overflow: hidden;
    padding-bottom: 20px;
    min-height: 100px;
}

footer:after {
    display: block;
    clear: both;
    content: "";
}


@media screen and (max-width: 1200px) {
    .header-logo {
        margin-left: 20px !important;
    }

    .header-nav .navbar {
        margin: 0 20px 0 0 !important;
    }

    .container {
        width: 100% !important;
    }
}

@media screen and (max-width: 991px) {
    footer .text-left,
    footer .text-right {
        float: none !important;
        margin-bottom: 6px;
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    footer {
        /*padding: 5px 0 !important;*/
        font-size: .7em;
        text-align: center;
    }

    .menu-toggle {
        display: block;
    }

    .content .md-col {
        margin: 10px 0 0 0;
        padding: 0;
        width: 100%;
    }
}

@media screen and (max-width: 520px) {
    footer .col-md-6,
    footer .text-right {
        text-align: center;
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

@media screen and (max-width: 480px) {
    .login-box {
        margin-top: 10px;
    }

    .content .right-col {
        margin-top: 0;
    }

    .content .left-col {
        margin-top: 0;
    }

    footer {
        height: auto !important;
    }

    footer .text-right {
        text-align: center
    }

    .dropdown-menu, .dropdown-item {
        white-space: normal;
    }
}

@media screen and (max-width: 379px) {
    header {
        text-align: center !important;
    }

    header .text-right {
        text-align: center !important;
    }
}

input[type="radio"] {
    display: inline-block;
    vertical-align: top;
    margin: 1px 8px 0 0;
}

input[type="radio"] + label {
    margin-right: 15px;
}


.mod {
    background-color: #F2F2F2;
    padding: 30px;
}

.glyphicon-eye-open {
    color: green;
}

.btn-facebook {
    margin: 0 !important;
    font-size: 16px !important;
}

.file-upload {
    position: relative;
    overflow: hidden;
    margin: 0;
    margin-bottom: 20px !important;
}

.file-upload input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.form-control {
    -ms-border-radius: 0;
    border-radius: 0;
}

.content {
    overflow: hidden;
    min-height: 500px;
}

/*------Common-------*/

.site-tile,
.site-header,
.site-header-table,
.site-carousel,
.site-breadcrumbs,
.site-second-level-menu,
.site-content,
.site-footer {
    border-collapse: collapse;
}

.orange {
    color: #f0a433;
}

.popover {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.form-group .captcha-container {
    text-align: center;
}

.form-group .captcha-container>div>div>div {
    margin: auto;
}

.text-left {
    text-align: left;
}

.logo-green {
    margin: 0 auto;
}

.loader div, .loader img {
    margin: 0 auto;
}

.btn-facebook.active, .btn-facebook.focus, .btn-facebook:active, .btn-facebook:focus, .btn-facebook:hover, .open > .dropdown-toggle.btn-facebook {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, .2);
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, .2);
}

.btn-kpi-id {
    color: #AAA;
    background-color: #000;
    border-color: rgba(0, 0, 0, .2);
    font-size: 16px;
}
.btn-kpi-id:hover {
    color: #fff;
}

.btn-social .fa-facebook {

}

.btn-social .icon {
    float: left;
    width: 30px;
    height: 35px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    margin-top: -5px;
    padding-top: 6px;
    padding-right: 10px;
}


.sad-cat {
    width: 200px;
    margin: 0 auto;
}
.info-button{
    vertical-align: middle;
    padding-right: 25px;
    font-size: 15pt;
    padding-top: 25px;
}

.info-button i{
    font-size: 20pt;
    padding-right: 10px;
}

.left-border{
    border-left: 1px solid rgba(0,0,0,.1);
}

.menu-icon:hover,
a:hover .menu-icon {
    text-decoration: none !important;
}

.menu-icon {
    padding-top: 15px;
}

.menu-icon .fa {
    font-size: 35pt;
    display: block;
    text-align: center;
}

.modal-body br {
    margin-bottom: 10px;
}

.study-group-info {
    text-align: left;
    border: 1px solid #AAA;
    width: 100%;
    margin-top: 5px;
    padding: 5px;
}

.study-group-info:hover {
    background-color: #AAA;
}

.study-group-info i {
    font-size: 8pt;
}