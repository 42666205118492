h3 {
    margin-top: 35px !important;
}

.employment-system {
    background-color: #e9ecef;
    padding: 2rem;
}

.btn-home {
    margin-right: 20px;
}

@media (max-width: 767px) {
    .btn-home {
        width: 100%;
        margin-right: auto;
        margin-bottom: 10px;
    }
}