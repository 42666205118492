h4 {
    margin-top: 15px !important;
}

.profile-img {
    text-align: center;
    overflow: hidden;
}

.profile-img img {
}

.profile-img .file {
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}

.profile-head h5 {
    color: #333;
}

.profile-head h6 {
    color: #0062cc;
}

.profile-edit-btn {
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
}

.profile-head .nav-tabs {
    margin-bottom: 5%;
}

.profile-head .nav-tabs .nav-link {
    font-weight: 600;
    border: none;
}

.profile-head .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid #0062cc;
}

.profile-work {
}

.profile-work p {
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
}

.profile-work a {
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
}

.profile-work ul {
    list-style: none;
}

.profile-tab label {
    font-weight: 600;
}

.profile-tab p {
    font-weight: 600;
    color: #0062cc;
}

.state-buttons .btn {
    width: 100%;
}

.warning-message {
    color: #F00;
}

.credo {
    font-style: italic;
    margin-bottom: 15px;
    color: #555;
}